import type {BaseItemObject} from '@PosterWhiteboard/items/item/item.types';
import type {SubtitleObject} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle.types';
import type {TextVerticalAlignType} from '@PosterWhiteboard/classes/text-styles.class';
import type {UpdateFromObjectOpts} from '@PosterWhiteboard/common.types';
import {ceilPrecision} from '@Utils/math.util';

export const ARE_AI_TRANSCRIPTS_ENABLED = true;

export interface TranscriptUpdateFromObjectOpts extends UpdateFromObjectOpts {
  onError?: () => void;
}

export type GeneratedFromType = 'video' | 'audio';

export const MIN_TIME_GAP_FOR_TRANSCRIPT = ceilPrecision(1 / 60, 9);

export interface TranscriptItemObject extends BaseItemObject {
  subtitlesHashmap: Record<string, SubtitleObject>;
  verticalAlign: TextVerticalAlignType;
  generatedFrom?: TranscriptGeneratedFrom;
}

export interface TranscriptGeneratedFrom {
  uid?: string;
  type: GeneratedFromType;
  hashedFilename: string;
  startTime: number;
  endTime: number;
  onPosterStartTime: number;
  loopCycles: number;
  speed: number;
}

export enum AiTranscriptAction {
  GENERATE = 'generate',
  REGENERATE = 'regenerate',
  GENERATED = 'generated',
}

export enum AiTranscriptSupportedLanguage {
  ENGLISH = 'en',
  SPANISH = 'es',
  ARABIC = 'ar',
  URDU = 'ur',
  AFRIKAANS = 'af',
  ARMENIAN = 'hy',
  AZERBAIJANI = 'az',
  BELARUSIAN = 'be',
  BOSNIAN = 'bs',
  BULGARIAN = 'bg',
  CATALAN = 'ca',
  CHINESE = 'zh',
  CROATIAN = 'hr',
  CZECH = 'cs',
  DANISH = 'da',
  DUTCH = 'nl',
  ESTONIAN = 'et',
  FINNISH = 'fi',
  FRENCH = 'fr',
  GALICIAN = 'gl',
  GERMAN = 'de',
  GREEK = 'el',
  HEBREW = 'he',
  HINDI = 'hi',
  HUNGARIAN = 'hu',
  ICELANDIC = 'is',
  INDONESIAN = 'id',
  ITALIAN = 'it',
  JAPANESE = 'ja',
  KANNADA = 'kn',
  KAZAKH = 'kk',
  KOREAN = 'ko',
  LATVIAN = 'lv',
  LITHUANIAN = 'lt',
  MACEDONIAN = 'mk',
  MALAY = 'ms',
  MARATHI = 'mr',
  MAORI = 'mi',
  NEPALI = 'ne',
  NORWEGIAN = 'no',
  PERSIAN = 'fa',
  POLISH = 'pl',
  PORTUGUESE = 'pt',
  ROMANIAN = 'ro',
  RUSSIAN = 'ru',
  SERBIAN = 'sr',
  SLOVAK = 'sk',
  SLOVENIAN = 'sl',
  SWAHILI = 'sw',
  SWEDISH = 'sv',
  TAGALOG = 'tl',
  TAMIL = 'ta',
  THAI = 'th',
  TURKISH = 'tr',
  UKRAINIAN = 'uk',
  VIETNAMESE = 'vi',
  WELSH = 'cy',
}

export const TRANSCRIPT_ITEM_PADDING = 32;
