import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import Emitter from '@/services/event-emitter';
import type {RootState} from '@/store';
import {isUserLoggedIn} from '@Libraries/user.library';

export interface UserPreferences {
  hideSocialMediaRemoveAccountConfirmation: boolean;
  isSpellCheckEnabled: boolean;
  isAutosaveEnabled: boolean;
  isSpellCheckAvailable: boolean;
  areAlignmentGuidesEnabled: boolean;
}

interface StateProps extends UserPreferences {
  isAutosavePreferenceUpdating: boolean;
  areAlignmentGuidesPreferenceUpdating: boolean;
  isSpellCheckPreferenceUpdating: boolean;
}

const initialState: StateProps = {
  hideSocialMediaRemoveAccountConfirmation: false,
  isSpellCheckEnabled: true,
  isAutosaveEnabled: true,
  isSpellCheckAvailable: false,
  areAlignmentGuidesEnabled: true,
  isAutosavePreferenceUpdating: false,
  areAlignmentGuidesPreferenceUpdating: false,
  isSpellCheckPreferenceUpdating: false,
};

const userPreferencesSlice = createSlice({
  name: 'userPreferences',
  initialState,
  reducers: {
    updateUserPreferences(state, action: PayloadAction<UserPreferences>) {
      state.hideSocialMediaRemoveAccountConfirmation = action.payload.hideSocialMediaRemoveAccountConfirmation;
      state.isSpellCheckEnabled = action.payload.isSpellCheckEnabled;
      state.isAutosaveEnabled = action.payload.isAutosaveEnabled;
      state.areAlignmentGuidesEnabled = action.payload.areAlignmentGuidesEnabled;
      state.isSpellCheckAvailable = action.payload.isSpellCheckAvailable;
    },
    updateIsSpellCheckEnabled(state, action: PayloadAction<boolean>) {
      state.isSpellCheckEnabled = action.payload;
    },
    updateIsAutosaveEnabled(state, action: PayloadAction<boolean>) {
      state.isAutosaveEnabled = action.payload;
    },
    updateAreAlignmentGuidesEnabled(state, action: PayloadAction<boolean>) {
      state.areAlignmentGuidesEnabled = action.payload;
    },
    updateIsAutosavePreferenceUpdating(state, action: PayloadAction<boolean>) {
      state.isAutosavePreferenceUpdating = action.payload;
    },
    updateAreAlignmentGuidesPreferenceUpdating(state, action: PayloadAction<boolean>) {
      state.areAlignmentGuidesPreferenceUpdating = action.payload;
    },
    updateIsSpellCheckPreferenceUpdating(state, action: PayloadAction<boolean>) {
      state.isSpellCheckPreferenceUpdating = action.payload;
    },
  },
});

export const {updateUserPreferences} = userPreferencesSlice.actions;
export const userPreferencesReducer = userPreferencesSlice.reducer;

export const setSpellCheckEnabledState = async (val: boolean): Promise<void> => {
  window.PMW.redux.store.dispatch(userPreferencesSlice.actions.updateIsSpellCheckPreferenceUpdating(true));
  try {
    await window.PMW.writeLocal('spellcheck/enableDisableSpellCheck', {
      enable: val ? 'true' : 'false',
    });
    window.PMW.redux.store.dispatch(userPreferencesSlice.actions.updateIsSpellCheckEnabled(val));
  } finally {
    window.PMW.redux.store.dispatch(userPreferencesSlice.actions.updateIsSpellCheckPreferenceUpdating(false));
  }
};

export const updateAreAlignmentGuidesEnabled = async (val: boolean): Promise<void> => {
  window.PMW.redux.store.dispatch(userPreferencesSlice.actions.updateAreAlignmentGuidesPreferenceUpdating(true));
  try {
    if (isUserLoggedIn()) {
      await window.PMW.writeLocal('user/updateAreAlignmentGuidesEnabled', {
        val: val ? '1' : '0',
      });
    }
    window.PMW.redux.store.dispatch(userPreferencesSlice.actions.updateAreAlignmentGuidesEnabled(val));
    Emitter.emit('userPreferences:areAlignmentGuidesEnabledUpdated');
  } finally {
    window.PMW.redux.store.dispatch(userPreferencesSlice.actions.updateAreAlignmentGuidesPreferenceUpdating(false));
  }
};

export const updateIsAutosaveEnabled = async (val: boolean): Promise<void> => {
  window.PMW.redux.store.dispatch(userPreferencesSlice.actions.updateIsAutosavePreferenceUpdating(true));
  try {
    if (isUserLoggedIn()) {
      await window.PMW.writeLocal('user/updateIsAutosaveEnabled', {
        val: val ? '1' : '0',
      });
    }
    window.PMW.redux.store.dispatch(userPreferencesSlice.actions.updateIsAutosaveEnabled(val));
  } finally {
    window.PMW.redux.store.dispatch(userPreferencesSlice.actions.updateIsAutosavePreferenceUpdating(false));
  }
};

export const getIsSpellCheckEnabled = (state: RootState): boolean => {
  return state.userPreferences.isSpellCheckAvailable && state.userPreferences.isSpellCheckEnabled;
};
