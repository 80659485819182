import type {ItemEffectsObject} from '@PosterWhiteboard/classes/item-effects.class';
import type {ItemAura, ItemAuraObject} from '@PosterWhiteboard/classes/item-aura.class';
import type {BorderType} from '@PosterWhiteboard/classes/item-border.class';
import type {TextStyles, TextStylesObject, TextVerticalAlignType} from '@PosterWhiteboard/classes/text-styles.class';
import type {RGBA} from '@Utils/color.util';
import type {TextListObject} from '@PosterWhiteboard/items/text-item/text-list';
import type {MorphType} from '@Libraries/fancy-text-library';
import type {Fill, FillObject} from '@PosterWhiteboard/classes/fill.class';
import type {TransitionObject} from '@PosterWhiteboard/models/transition.class';
import type {LayoutBackgroundTypes, LayoutTypes} from '@PosterWhiteboard/items/layouts/layout.types';
import type {SeparatorType} from '@PosterWhiteboard/items/tabs-item/tabs-item.class';
import type {LineDash, LinePointsStyle} from '@PosterWhiteboard/items/line-item/pmw-fabric-line';
import type {MediaSlideObject} from '@PosterWhiteboard/items/slideshow-item/media-slide.class';
import type {TextBackground} from '@PosterWhiteboard/items/text-item/text-background';
import type {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {SubtitleTemplateType} from '@PosterWhiteboard/items/transcript-item/subtitle/template-styles.types';
import type {ImageItemSourceType} from '@Libraries/image-item.library';
export enum PosterItemControlOptions {
  BACKWARD = 'backward',
  FORWARD = 'forward',
  DUPLICATE = 'duplicate',
  COPY_STYLES = 'copy_styles',
  DELETE = 'delete',
}

export enum CopyStyleState {
  COPY = 'copy',
  COPIED = 'copied',
}

export type CopyableItemStylesAndProperties =
  | CommonProperties
  | StickerItemStyles
  | ImageItemStylesAndProperties
  | QRCodeItemStyles
  | VideoItemStyles
  | FancyTextItemStyles
  | TextItemStyles
  | VectorItemStyles
  | SlideShowItemStyles
  | TextSlideItemStyles
  | ImageSlideItemStyles
  | VideoSlideItemStyles
  | TableItemStyles
  | MenuItemStyles
  | TabsItemStyles
  | ImageBackgroundItemStyles
  | RectangleItemStyles
  | LineItemStyles
  | TranscriptItemStyles;

export interface CommonProperties {
  gitype: ITEM_TYPE;
  opacity: number;
  border: BorderItemStyles;
  aura: ItemAuraObject;
  flipX: boolean;
  flipY: boolean;
  scaleX: number;
  scaleY: number;
}

export const DEVICE_TYPE = {
  MOBILE: 'mobile',
};

export interface StickerItemStyles extends CommonProperties {
  effects: ItemEffectsObject;
}

export interface ImageItemStylesAndProperties extends CommonProperties {
  imageSource: ImageItemSourceType;
  effects: ItemEffectsObject;
}

export interface BorderItemStyles extends CommonProperties {
  solidBorderType: BorderType;
  solidBorderColor: RGBA;
  solidBorderThickness: number;
}

export interface QRCodeItemStyles extends CommonProperties {
  qrForegroundColor: RGBA;
  qrBackgroundColor: RGBA;
  isBackgroundTransparent: boolean;
}

export interface VideoItemStyles extends CommonProperties {
  effects: ItemEffectsObject;
}

export interface FancyTextItemStyles extends CommonProperties {
  fontFamily: string;
  idFancyText: string;
  colors: RGBA[];
  morphType: MorphType;
  morphAmount: number;
}

export interface TextItemStyles extends CommonProperties {
  textStyles: TextStylesObject;
  backgroundType: number;
  backgroundColor: RGBA;
  background: TextBackground;
  verticalAlign: TextVerticalAlignType;
  verticalPadding: number;
  list: TextListObject;
}

export interface VectorItemStyles extends CommonProperties {
  fill: FillObject;
}

export interface SlideShowItemStyles extends TextSlideItemStyles, ImageSlideItemStyles, VideoSlideItemStyles, CommonProperties {
  introAnimationPadding: number;
  introDelay: number;
  hasIntroOutroTransition: boolean;
  transition: TransitionObject;
}

export interface TextSlideItemStyles extends TextItemStyles, CommonProperties {
  slideDuration: number;
}

export interface ImageSlideItemStyles extends ImageItemStylesAndProperties, CommonProperties {
  slideDuration: number;
  mediaSlide: MediaSlideObject;
}

export interface VideoSlideItemStyles extends VideoItemStyles, CommonProperties {
  slideDuration: number;
  mediaSlide: MediaSlideObject;
}

export interface TableItemStyles extends CommonProperties {
  layoutStyle: LayoutTypes;
  alternateBackgroundColor1: RGBA;
  alternateBackgroundColor2: RGBA;
  highlightedBackgroundColor: RGBA;
  highlightedTextColor: RGBA;
  xSpacing: number;
  ySpacing: number;
  fontFamily2: string;
  isBold2: boolean;
  isItalic2: boolean;
  underLine2: boolean;
  lineThrough2: boolean;
  backgroundType: LayoutBackgroundTypes;
  backgroundColor: RGBA;
  textStyles: TextStyles;
}

export interface MenuItemStyles extends TableItemStyles, CommonProperties {
  iconsColor: RGBA;
  iconsSize: number;
}

export interface TabsItemStyles extends CommonProperties {
  separatorColor: RGBA;
  separatorType: SeparatorType;
  backgroundType: LayoutBackgroundTypes;
  backgroundColor: RGBA;
  textStyles: TextStyles;
}

export interface ImageBackgroundItemStyles extends ImageItemStylesAndProperties, CommonProperties {}

export interface RectangleItemStyles extends CommonProperties {
  rx: number;
  ry: number;
  fill: Fill;
}

export interface LineItemStyles extends CommonProperties {
  fill: Fill;
  lineDash: LineDash;
  lineEnd: LinePointsStyle;
  lineStart: LinePointsStyle;
}

export interface TranscriptItemStyles extends CommonProperties {
  verticalAlign: TextVerticalAlignType;
  subtitleStyles: SubtitleItemStyles;
}

export interface SubtitleItemStyles {
  sentenceTextStyles: TextStyles;
  animationStyle: SubtitleTemplateType;
  activeWordTextStyles: TextStyles;
  backgroundFill: Fill;
  aura: ItemAura;
  backgroundBorderRadius: number;
}
