/**
 * This contains helper functions for color
 *
 * @author Afifa Saleem <afifa@250mils.com>
 */
import tinycolor from 'tinycolor2';
import Values from 'values.js';
import {FillTypes} from '@PosterWhiteboard/classes/fill.class';
import {ColorFillPropertyType} from '@Components/color-fill';
import type {ColorStorage} from '@Components/color-picker-v2';
import {COLOR_OBJECT_IDS} from '@Components/poster-editor/components/poster-editing-side-panel/poster-editing-side-panel.types';
import {remove} from 'lodash';

export interface GradientColors {
  color1: RGB;
  color2: RGB;
}

export const PMW_COLORS_NEUTRAL = {
  NEUTRAL_0: '#FFFFFF',
  NEUTRAL_1: '#FAFAFA',
  NEUTRAL_3: '#F2F2F5',
  NEUTRAL_6: '#E6E6EC',
  NEUTRAL_11: '#DCDCE1',
  NEUTRAL_18: '#CBCBD0',
  NEUTRAL_28: '#A1A1AA',
  NEUTRAL_70: '#403F5D',
};

export const PMW_COLORS_PRIMARY = {
  PRIMARY_50: '#9FDDF3',
  PRIMARY_100: '#3FBCE8',
  PRIMARY_150: '#23A6D5',
  PRIMARY_200: '#00688C',
};

export const PMW_COLORS_100 = {
  PRIMARY: '#3FBCE7',
  SECONDARY: '#9577E7',
  SUCCESS: '#24C19C',
};

export type RGB = [number, number, number];
export type RGBA = [number, number, number, number];
export interface Rgbacolor {
  r: number;
  g: number;
  b: number;
  a: number;
}

export const rgbToHex = (color: RGB, alpha?: number): string => {
  if (alpha === undefined) {
    return tinycolor({
      r: color[0],
      g: color[1],
      b: color[2],
    }).toHex();
  }

  return tinycolor({
    r: color[0],
    g: color[1],
    b: color[2],
    a: alpha,
  }).toHex8();
};

export const rgbaToHex = (color: RGBA, shouldIncludeAlpha = true): string => {
  if (shouldIncludeAlpha) {
    return tinycolor({
      r: color[0],
      g: color[1],
      b: color[2],
      a: color[3],
    }).toHex8();
  }
  return tinycolor({
    r: color[0],
    g: color[1],
    b: color[2],
  }).toHex();
};
export const rgbaToHexString = (color: RGBA): string => {
  return tinycolor({
    r: color[0],
    g: color[1],
    b: color[2],
    a: color[3] ?? 1,
  }).toHex8String();
};

export const rgbToHexString = (color: RGB, alpha?: number): string => {
  if (alpha === undefined) {
    return tinycolor({
      r: color[0],
      g: color[1],
      b: color[2],
    }).toHexString();
  }

  return tinycolor({
    r: color[0],
    g: color[1],
    b: color[2],
    a: alpha,
  }).toHex8String();
};

export const rgbToRGBString = (color: RGB, alpha?: number): string => {
  if (alpha === undefined) {
    return tinycolor({
      r: color[0],
      g: color[1],
      b: color[2],
    }).toRgbString();
  }

  return tinycolor({
    r: color[0],
    g: color[1],
    b: color[2],
    a: alpha,
  }).toRgbString();
};

export const rgbaToRGBAString = (color: RGBA): string => {
  return tinycolor({
    r: color[0],
    g: color[1],
    b: color[2],
    a: color[3],
  }).toRgbString();
};
export const hextToHexString = (color: string): string => {
  return `#${color}`;
};

export const hexStringToHext = (color: string): string => {
  if (color.length > 1 && color.startsWith('#')) {
    return color.slice(1);
  }

  return color;
};

export const hexToRgb = (hex: string): RGB => {
  const dec = parseInt(hex.includes('#') ? hex.substring(1) : hex, 16);
  return [dec >> 16, (dec & 0x00ff00) >> 8, dec & 0x0000ff];
};
export const hexToRgba = (hex: string, alpha = 1): RGBA => {
  const dec = parseInt(hex.includes('#') ? hex.substring(1) : hex, 16);
  return [dec >> 16, (dec & 0x00ff00) >> 8, dec & 0x0000ff, alpha];
};

export const rgbaToRgbacolor = (color: RGBA): Rgbacolor => {
  return {
    r: color[0],
    g: color[1],
    b: color[2],
    a: color[3] ?? 1,
  };
};

export const rgbacolorToRgba = (color: Rgbacolor): RGBA => {
  return [color.r, color.g, color.b, color.a];
};

/**
 * Get hex color value provided color in any format
 * @param {*} color
 */
export const colorToHex = (color: tinycolor.ColorInput) => {
  return tinycolor(color).toHex();
};

/**
 * returns any color into a formatted hex string in the format: #000000
 * @param color
 * @returns {string}
 */
export const colorToHexString = (color: tinycolor.ColorInput) => {
  return tinycolor(color).toHexString();
};

/**
 * Remove characters not allowed in hex format string
 */
export const removeUnallowedCharacters = (color: string): string => {
  return color ? color.replace(/[^a-fA-F0-9]+/, '') : '';
};

/**
 * Check if color string has 3/6 characters allowed for hex string
 */
export const isCorrectHexFormat = (color: string) => {
  return /^([A-Fa-f0-9]{6})$/i.test(color);
};

/**
 * Get formatted hex value for color
 */
export const getFormattedHexColor = (color: string) => {
  let newColor = color;
  if (!isCorrectHexFormat(color)) {
    newColor = color = removeUnallowedCharacters(color);
    if (color.length < 4) {
      newColor = color[0] ? color[0] + color[0] : '0';
      newColor = color[1] ? newColor + color[1] + color[1] : newColor;
      newColor = color[2] ? newColor + color[2] + color[2] : newColor;
    }
    newColor = `${newColor}000000`.slice(0, 6);
  }
  return newColor;
};

/**
 * generates a color palette with lighter shades of the input color based on the provided weight
 * the higher the weight, the lighter the output colors
 * @param {string} inputColor
 * @param {number} weight
 * @returns {Array}
 */
export const generateColorPaletteForWeight = (inputColor: tinycolor.ColorInput, weight: number) => {
  const color = colorToHexString(inputColor);
  const colorPalette = new Values(color);
  const tints = colorPalette.tints(weight);
  const tintHexes = tints.map((tint) => {
    return tint.hexString();
  });

  return remove(tintHexes, tintHexes.length - 1);
};

/**
 * Generates a color palette with lighter tones than the input color using '20' as a weight
 * This generates 5 colors.
 * @param {string} inputColor
 * @returns {Array}
 */
export const generateLighterToneColorPaletteForColor = (inputColor: tinycolor.ColorInput) => {
  return generateColorPaletteForWeight(inputColor, 20);
};

export const colorToRGBA = (c: any, alpha = 1): RGBA => {
  try {
    if (typeof c === 'object') {
      return c;
    }
    let color = c;
    if (typeof c === 'string') {
      color = c.toLowerCase().replace('rgb', '').replace('(', '').replace(')', '');
      // see if the input string is in the rgb format
      const parts = c.split(',');
      if (parts.length === 3) {
        return [parseInt(parts[0], 10), parseInt(parts[1], 10), parseInt(parts[2], 10), alpha];
      }

      // see if the string is in the hex format
      if (color.indexOf('0x') === 0) {
        color = parseInt(color, 10);
      } else if (color.indexOf('#') !== -1) {
        return hexToRgba(color);
      } else {
        // try parsing the string
        color = parseInt(color, 10);
        if (isNaN(color)) {
          color = 0; // default to black
        }
      }
    }
    const r = (color >> 16) & 0xff;
    const g = (color >> 8) & 0xff;
    const b = color & 0xff;
    return [r, g, b, alpha];
  } catch (e) {
    return [0, 0, 0, alpha]; // default to black in case of error
  }
};

export const rgbaToRgbaString = (RGBA: RGBA): string => {
  if (RGBA && RGBA.length > 3) {
    return `rgba(${RGBA[0]},${RGBA[1]},${RGBA[2]},${RGBA[3]})`;
  }
  return '';
};

export const getRgbLuminance = (rgb: number[]) => {
  return ((0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]) / 255) * 100;
};

/**
 * Gives a lighter/darker shade of a hex color string (format: #000000)
 *
 * @returns {string}
 * @param color
 * @param factor
 */

export const lightenDarkenColor = (color: RGBA, factor = 100): string => {
  const usePound = false;
  const luminanceFactor = getRgbLuminance(color) > 50 ? factor * -1 : factor;
  const hex = rgbaToHex(color, false);

  const num = parseInt(hex, 16);
  let r = (num >> 16) + luminanceFactor;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + luminanceFactor;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + luminanceFactor;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + `000000${(g | (b << 8) | (r << 16)).toString(16)}`.slice(-6);
};

/**
 * This function is specifically for the new poster editor
 * It converts the fill type property of a graphic item to color fill property type which is then used in color picker v2
 */
export const getColorFillTypeFromFillType = (fillType: FillTypes): ColorFillPropertyType | undefined => {
  switch (fillType) {
    case FillTypes.LINEAR_GRADIENT:
      return ColorFillPropertyType.LINEAR;
    case FillTypes.RADIAL_GRADIENT:
      return ColorFillPropertyType.RADIAL;
    case FillTypes.SOLID:
      return ColorFillPropertyType.SOLID;
    case FillTypes.NONE:
      return undefined;
    default:
      throw new Error('Unhandled fill type in getColorFillTypeFromFillType');
  }
};

/**
 * This function is specifically for the new poster editor
 * It converts the color fill property type to the fill type property of a graphic item
 */
export const getFillTypeFromColorFillType = (colorFillType: ColorFillPropertyType): FillTypes => {
  switch (colorFillType) {
    case ColorFillPropertyType.SOLID:
      return FillTypes.SOLID;
    case ColorFillPropertyType.LINEAR:
      return FillTypes.LINEAR_GRADIENT;
    case ColorFillPropertyType.RADIAL:
      return FillTypes.RADIAL_GRADIENT;
    default:
      throw new Error('unhandled color fill property type');
  }
};

/**
 * This function is specifically for the new poster editor
 * It converts color storage object to Array of RGBA which is the actual type for fillColor for graphic items containing fill
 */
export const getRGBAArrayFromColorStorage = (array: ColorStorage[]): RGBA[] => {
  const rgbaArray: RGBA[] = [];
  for (let index = 0; index < array.length; index += 1) {
    rgbaArray.push(array[index].color);
  }
  return rgbaArray;
};

/**
 * This function is specifically for the new poster editor
 * It converts fillColor RGB Array to color storage object which is then passed to the color picker
 */
export const getColorObjectArrayFromFillColor = (fillColor: RGBA[]): ColorStorage[] => {
  const colorObjectArray: ColorStorage[] = [];
  for (let index = 0; index < fillColor.length; index += 1) {
    colorObjectArray.push({
      id: COLOR_OBJECT_IDS[index],
      color: fillColor[index],
    });
  }
  return colorObjectArray;
};

/**
 * Generates two colors, color2 and color3, based on the user-selected color (color1).
 * If color1 is determined to be a light color, color2 will be darker, and color3 will be even darker.
 * If color1 is determined to be a dark color, color2 will be lighter, and color3 will be even lighter.
 * The degree of darkness or lightness is controlled by the shadeIncrement factor.
 *
 * @param {string} color1 - The hex color code selected by the user.
 * @returns {string[]} An array containing three hex color codes: [color1, color2, color3].
 *                    Color2 and color3 are incrementally darker or lighter than color1 based on its shade.
 *                    Returns null if the input color format is invalid.
 */
export const generateIncrementalShadedColors = (color1: string): string[] => {
  // Ensure the input is a valid hex color
  const hexRegex = /^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/;

  if (!hexRegex.test(color1)) {
    throw new Error('Invalid color passed to generateIncrementalShadedColors()');
  }

  // Function to calculate perceived brightness
  function calculatePerceivedBrightness(hex: string): number {
    // Parse the hex color to RGB
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    // Calculate perceived brightness (standard luminance formula)
    return (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  }

  // Determine if the user-selected color is light or dark based on perceived brightness
  const perceivedBrightness = calculatePerceivedBrightness(color1);
  const isLightColor = perceivedBrightness > 0.5;

  // Calculate factors for incremental shades
  const shadeIncrement = 0.35;
  const color2Factor = isLightColor ? 1 - shadeIncrement : 1 + shadeIncrement;
  const color3Factor = isLightColor ? 1 - shadeIncrement * 1.5 : 1 + shadeIncrement * 2;

  // Function to adjust brightness
  function adjustBrightness(hex: string, factor: number): string {
    // Parse the hex color to RGB
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    // Adjust each component based on the factor
    const adjustedR = Math.min(255, Math.max(0, Math.round(r * factor)));
    const adjustedG = Math.min(255, Math.max(0, Math.round(g * factor)));
    const adjustedB = Math.min(255, Math.max(0, Math.round(b * factor)));

    // Convert back to hex
    return `#${[adjustedR, adjustedG, adjustedB]
      .map((value) => {
        return value.toString(16).padStart(2, '0');
      })
      .join('')}`;
  }

  // Generate color2 and color3
  const color2 = adjustBrightness(color1, color2Factor);
  const color3 = adjustBrightness(color1, color3Factor);

  return [color1, color2, color3];
};

export const darkenColor = (colorHex: string, percentage: number = 20): string => {
  return tinycolor(colorHex).darken(percentage).toHexString();
};
