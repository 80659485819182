import {isWebpSupported} from '@Utils/browser.util';
import {getImageItemBigScreenSizeRemovedBackgroundS3Key} from '@Libraries/image-item.library';

interface RemoveBackgroundResponse {
  remainingRemoveBackgroundCredits: number;
}

interface UploadingImageStatusResponse {
  status: UploadStatus;
  hashedFilename: string;
  fileExtension: string;
}

export enum UploadStatus {
  SUCCESS = 'image_upload_succeeded',
  FAILED = 'image upload failed',
}

const REMOVED_BG_IMAGE_COST = 1;
const UPLOADING_IMAGE_STATUS_TIMEOUT = 600000;

export const removeImageBackground = async (imageHashedFilename: string): Promise<RemoveBackgroundResponse> => {
  return (await window.PMW.writeLocal('posterimage/removeUserImageBackground', {
    userImageHashedFilename: imageHashedFilename,
  })) as Promise<RemoveBackgroundResponse>;
};

export const getRemoveBackgroundCost = async (hashedFilename: string, imageSource: string): Promise<number> => {
  if (await doesRemovedBackgroundExists(hashedFilename, imageSource)) {
    return 0;
  }
  return REMOVED_BG_IMAGE_COST;
};

export const doesRemovedBackgroundExists = async (hashedFilename: string, imageSource: string): Promise<boolean> => {
  return (await window.PMW.readLocal('posterimage/doesRemovedBackgroundExistsForImage', {
    userImageS3Key: getImageItemBigScreenSizeRemovedBackgroundS3Key(hashedFilename, imageSource),
  })) as boolean;
};

/**
 * Returns whether the filetype is of an image
 * @param {string} fileType
 * @returns {boolean}
 */
export const isFiletypeImage = (fileType: string) => {
  if (fileType === '') {
    return false;
  }
  return fileType.indexOf('image/') !== -1;
};

/**
 * Returns the browser compatible file extension
 * @param {string} fileExtension
 * @return {string}
 */
export const getCompatibleImageFileExtension = (fileExtension: string): string => {
  const FALLBACK_IMAGE_EXTENSION_FOR_WEBP = 'png';
  if (fileExtension === 'webp' && !isWebpSupported()) {
    return FALLBACK_IMAGE_EXTENSION_FOR_WEBP;
  }
  return fileExtension;
};

/**
 * @param {string} src
 * @returns {mise<number[]>Pro}
 */
export const getImageDimensions = async (src: string): Promise<[number, number]> => {
  const img = await loadImageAsync(src);
  return [img.naturalWidth, img.naturalHeight];
};

export const removeAlmostTranparentPixels = async (img: HTMLImageElement): Promise<HTMLImageElement> => {
  const ALPHA_THRESHOLD = 3;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error(`Failed to get context of canvas`);
  }

  canvas.width = img.width;
  canvas.height = img.height;
  ctx.drawImage(img, 0, 0);

  const imgd = ctx.getImageData(0, 0, img.width, img.height);
  const pix = imgd.data;

  for (let i = 0, n = pix.length; i < n; i += 4) {
    if (pix[i + 3] < ALPHA_THRESHOLD) {
      pix[i + 3] = 0;
    }
  }

  ctx.putImageData(imgd, 0, 0);
  const url = canvas.toDataURL();
  return loadImageAsync(url);
};

export const hasTransparency = (img: HTMLImageElement): boolean => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error(`Failed to get context of canvas`);
  }
  canvas.width = img.width;
  canvas.height = img.height;
  ctx.drawImage(img, 0, 0);

  const {data} = ctx.getImageData(0, 0, canvas.width, canvas.height);
  let hasAlphaPixels = false;
  for (let i = 3, n = data.length; i < n; i += 4) {
    if (data[i] < 255) {
      hasAlphaPixels = true;
      break;
    }
  }
  return hasAlphaPixels;
};

/**
 * @param {string} src
 * @returns {Promise<number>}
 */
export const getImageRatio = async (src: string): Promise<number> => {
  const [width, height] = await getImageDimensions(src);
  return width / height;
};

/**
 * Loads image with a promise
 * @param src
 * @return {Promise<HTMLImageElement>}
 */
export const loadImageAsync = (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = (): void => {
      resolve(img);
    };
    img.crossOrigin = 'anonymous';
    img.onerror = reject;
    img.src = src;
  });
};

export const loadImageSync = (url: string, successCB: (img: any) => any, failCB: () => any, setCrossOrigin = true): void => {
  const img = new Image();
  img.onload = successCB.bind(this, img);
  if (setCrossOrigin) {
    img.crossOrigin = 'anonymous';
  }
  img.onerror = failCB;
  img.onabort = failCB;
  img.src = url;
};

export const dataURLToArray = (imageDataURL: string): Promise<Uint8ClampedArray> => {
  return new Promise((resolve, reject) => {
    const imageElem = document.createElement('img');
    imageElem.src = imageDataURL;

    imageElem.onload = (): void => {
      const w = imageElem.width;
      const h = imageElem.height;

      const canvas = document.createElement('canvas');
      canvas.width = imageElem.width;
      canvas.height = imageElem.height;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error(`Failed to get context of canvas`));
        return;
      }
      ctx.drawImage(imageElem, 0, 0);

      resolve(ctx.getImageData(0, 0, w, h).data);
    };
  });
};

export const imageUrlToDataUrl = async (imageUrl: string): Promise<string> => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = (): void => {
      resolve(reader.result as string);
    };
    reader.onerror = (error): void => {
      reject(error);
    };
    reader.readAsDataURL(blob);
  });
};

export const imageFileToDataUrl = async (imageFile: File): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e): void => {
      if (e.target) {
        const dataURL = e.target.result;
        resolve((dataURL as string) ?? undefined);
      }
      resolve(undefined);
    };
    reader.onerror = (error): void => {
      reject(error);
    };
    reader.readAsDataURL(imageFile);
  });
};

export const getUserImageHashedFilenameForUploadingImage = async (
  tempUploadingImageUIDRetrievalFunction: () => string | Promise<string>
): Promise<UploadingImageStatusResponse> => {
  return window.PMW.pollingAjaxCallAsync(
    tempUploadingImageUIDRetrievalFunction as () => Promise<string>,
    checkUploadingImageStatus,
    UPLOADING_IMAGE_STATUS_TIMEOUT
  ) as Promise<UploadingImageStatusResponse>;
};

const checkUploadingImageStatus = async (tempUploadingImageUID: string): Promise<any> => {
  return window.PMW.readLocal('posterimage/getUserImageHashedFilenameForUploadingImage', {
    tempUploadingImageUID,
  });
};
