export enum AiImageState {
  IDLE = 'IDLE',
  GENERATING = 'GENERATING',
  READY = 'READY',
}

export enum AiSubtitleState {
  IDLE = 'IDLE',
  GENERATING = 'GENERATING',
  READY_SUCCESS = 'READY_SUCCESS',
  READY_FAILED = 'READY_FAILED',
}

export enum AiVoiceState {
  IDLE = 'IDLE',
  GENERATING = 'GENERATING',
  READY = 'READY',
  ERROR = 'ERROR',
}

export interface AiState {
  aiImage: AiImageState;
  aiSubtitles: AiSubtitleState;
  aiVoice: AiVoiceState;
}
